import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { withPrefix } from "gatsby"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

const NotFound = () => {
  useEffect(() => {
    console.log("we use the effect")
    navigate(withPrefix("/featured"))
  }, [])

  return (
    <Layout>
      <SEO title="404" />
      <div className="py-20 container max-w-screen-md">
        <h3 className="mb-4 text-xl font-semibold">
          Cette page est introuvable!
        </h3>
        <p className="mb-8">
          <Link
            className="border-b border-current duration-300 ease-in-out hover:border-0 hover:opacity-50 no-underline transition"
            to="/"
          >
            Retournez à l’accueil
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default NotFound
